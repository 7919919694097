.Editor {
  background: white;

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 500px
  }

  .ql-snow {
    line-height: 0;
  }
}

